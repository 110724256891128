/** @format */

import { BookingErrorStates, TReducerBooking } from '@/ducks/bookingNew/types';
import { TBookingAction } from '@/redux/declarations';
import { DEFAULT_BOOKING } from '@/ducks/bookingNew/constants';

export const reducerBooking = (
  prevState: TReducerBooking = DEFAULT_BOOKING,
  action: TBookingAction,
): TReducerBooking => {
  switch (action.type) {
    case 'BOOKING_SET_ROOM':
      return {
        ...prevState,
        room: action.payload,
      };
    case 'BOOKING_AVAILABILITY_REQUEST':
      return {
        ...prevState,
        loading: prevState.loading + 1,
        loadingAvailability: true,
      };
    case 'BOOKING_CONTRACTUAL_REQUEST':
    case 'BOOKING_COMMERCIAL_REQUEST':
      return {
        ...prevState,
        loading: prevState.loading + 1,
      };
    case 'BOOKING_OPTIONS_REQUEST':
      return {
        ...prevState,
        loading: prevState.loading + 1,
      };
    case 'BOOKING_LIGHT_IDENTITY_REQUEST':
      return { ...prevState, loading: prevState.loading + 1 };
    case 'BOOKING_LIGHT_IDENTITY_SUCCESS':
      return { ...prevState, loading: prevState.loading - 1, lightIdentity: action.payload };
    case 'BOOKING_LIGHT_IDENTITY_FAILURE':
      return { ...prevState, loading: prevState.loading - 1 };
    case 'BOOKING_VALIDATE_ROOM_REQUEST':
      return {
        ...prevState,
        loading: prevState.loading + 1,
      };
    case 'BOOKING_VALIDATE_ROOM_SUCCESS':
      return {
        ...prevState,
        loading: prevState.loading - 1,
      };
    case 'BOOKING_AVAILABILITY_SUCCESS':
      return {
        ...prevState,
        availability: action.payload,
        loading: prevState.loading - 1,
        loadingAvailability: false,
      };
    case 'BOOKING_OPTIONS_SUCCESS':
      return {
        ...prevState,
        bookingOptions: action.payload,
        loading: prevState.loading - 1,
      };
    case 'BOOKING_CONTRACTUAL_SUCCESS':
      return {
        ...prevState,
        contractualConditions: action.payload,
        loading: prevState.loading - 1,
      };
    case 'BOOKING_COMMERCIAL_SUCCESS':
      return {
        ...prevState,
        commercialConditions: action.payload,
        loading: prevState.loading - 1,
      };
    case 'BOOKING_VALIDATE_ROOM_FAILURE':
    case 'BOOKING_AVAILABILITY_FAILURE':
      return {
        ...prevState,
        error: BookingErrorStates.NO_AVAILABILITY,
        loading: prevState.loading - 1,
        loadingAvailability: false,
      };
    case 'BOOKING_CONTRACTUAL_FAILURE':
    case 'BOOKING_COMMERCIAL_FAILURE':
    case 'BOOKING_OPTIONS_FAILURE':
    case 'BOOKING_GENERIC_FAILURE':
      return {
        ...prevState,
        error: BookingErrorStates.GENERIC_API,
        loading: prevState.loading - 1,
      };
    case 'BOOKING_SET_CHECK_IN_DATE':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          checkInDate: action.payload,
        },
      };
    case 'BOOKING_SET_CHECK_OUT_DATE':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          checkOutDate: action.payload,
        },
      };
    case 'BOOKING_SET_CONTACT_DETAILS':
      return {
        ...prevState,
        step: 'personalDetails',
        applicationForm: {
          ...prevState.applicationForm,
          contactDetails: action.payload,
        },
      };
    case 'BOOKING_SET_PERSONAL_DETAILS':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          personalDetails: action.payload,
        },
      };
    case 'BOOKING_SET_PERSONAL_DETAILS_ERROR':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          personalDetailsError: action.payload,
        },
      };
    case 'BOOKING_SET_SECOND_OCCUPANT':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          secondOccupant: action.payload,
        },
      };
    case 'BOOKING_SET_SECOND_OCCUPANT_ERROR':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          secondOccupantError: action.payload,
        },
      };
    case 'BOOKING_SET_RESIDENTIAL_ADDRESS':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          residentialAddress: action.payload,
        },
      };
    case 'BOOKING_SET_RESIDENTIAL_ADDRESS_ERROR':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          residentialAddressError: action.payload,
        },
      };
    case 'BOOKING_SET_FINANCIAL_VALIDATION':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          financialValidation: action.payload,
        },
      };
    case 'BOOKING_SET_FINANCIAL_VALIDATION_ERROR':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          financialValidationError: action.payload,
        },
      };
    case 'BOOKING_SET_VALID_CHECKOUT':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          validCheckOutDates: action.payload,
        },
      };
    case 'BOOKING_SET_STEP': {
      return {
        ...prevState,
        step: action.payload,
      };
    }
    case 'BOOKING_SET_HOW_FIND_US': {
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          howDidYouFindUs: action.payload,
        },
      };
    }
    case 'BOOKING_SET_HOW_FIND_US_ERROR': {
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          howDidYouFindUsError: action.payload,
        },
      };
    }
    case 'BOOKING_ACTIVATE_PAYMENT_STEP': {
      return {
        ...prevState,
        step: 'payment',
      };
    }
    default:
      return prevState;
  }
};
