/** @format */

import { TRoom } from '@/ducks/room/declarations';

export interface EvaluedExitWindows {
  startDate: string;
  endDate: string;
  evaluedPrice: number;
  ranking: number;
}

export interface ValidCheckOutDates {
  from: string;
  to: string;
  validDates: string[];
  evaluatedDates: EvaluedExitWindows[];
}

export interface RoomAvailability {
  price: number;
  discountedPrice?: number;
  discountUntil?: string;
  visibleOnline: boolean;
  availableFrom: string;
  availableTo: string;
  bedAvailabilityId: string;
  bedCode: string;
  checkInDates: string[];
  negotiating: boolean;
  status: 'Free' | '';
  checkoutDateFrom: string;
  checkoutDateTo: string;
  minimumStay: number;
  salableOnline: boolean;
  defaultCheckoutDate: string;
  validCheckoutDates: string[];
  evaluedExitWindows?: EvaluedExitWindows[];
}

export interface ContractualConditions {
  adminFeeIn: number;
  adminFeeOut: number;
  cancellationMonthNumber: number;
  cityId: string;
  code: string;
  contractModel: 'CNTIND' | 'CNTDET';
  contractualConditionId: 'e234900b-adec-ec11-bb3d-000d3adc5e94';
  durationFrom: number;
  durationTo: number;
  legalContractType: 'Type5';
  legalEntityId: string;
  name: string;
  paymentOption: string;
  type: 'Uncertain';
}

export interface CommercialConditions {
  confirmationDeposit: number;
  depositAmount: number;
  depositMonthsNumber: number;
  discountPercentage: number;
  discountUntil: string;
  exitOptions: boolean;
  firstMonthRent: number;
  maxVacancyDays: number;
  minStayDays: number;
  preCleaningDays: number;
  priceVariationPercentage: number;
  price: number;
  commercialConditionsId: string;
  privateSale: boolean;
  discountedPrice: number;
}

export interface BookingContactDetails {
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
}

export enum GenderOptions {
  MALE = 'Male',
  FEMALE = 'Female',
}

export enum ProfessionOptions {
  STUDENT = 'Student',
  WORKER = 'Worker',
  INTERN = 'Intern',
  OTHER = 'Other',
}

export enum BookingErrorStates {
  GENERIC_API = 'generic_api',
  NO_AVAILABILITY = 'no_availability',
}

export enum SecondOccupantOptions {
  NO = 'no',
  YES = 'yes',
}

export interface BookingPersonalDetails {
  sex?: GenderOptions;
  nationality?: string;
  countryOfBirth?: string;
  cityOfBirth?: string;
  dateOfBirth?: string;
  taxCode?: string;
  occupation?: ProfessionOptions;
  preferredLanguage?: string;
}

interface SecondOccupantYes {
  secondOccupant: SecondOccupantOptions.YES;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
}

interface SecondOccupantNo {
  secondOccupant: SecondOccupantOptions.NO;
}

export type BookingSecondOccupant = SecondOccupantYes | SecondOccupantNo;

export interface BookingResidentialAddress {
  country: string;
  province: string;
  city: string;
  zipCode: string;
  address: string;
  houseNumber: string;
}

export interface BookingCountry {
  value: string;
  label: string;
  countryCode: string;
  isEu: boolean;
}

export interface BookingValueLabel {
  value: string;
  label: string;
}

export interface BookingOptions {
  countries: BookingCountry[];
  genders: BookingValueLabel[];
  nationalities: BookingCountry[];
  prefixes: BookingValueLabel[];
  professions: BookingValueLabel[];
  provinces: BookingValueLabel[];
}

export enum FinancialProvider {
  MYSELF = 'MYSELF',
  GUARANTOR = 'GUARANTOR',
  GARAMTME = 'GARANTME',
  SMARTGARANT = 'SMARTGARANT',
  STUDAPART = 'STUDAPART',
}

interface FinancialValidationNoForm {
  provider:
    | FinancialProvider.MYSELF
    | FinancialProvider.GARAMTME
    | FinancialProvider.SMARTGARANT
    | FinancialProvider.STUDAPART
    | null;
}

interface FinancialValidationWithForm {
  provider: FinancialProvider.GUARANTOR;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

export type BookingFinancialValidation = FinancialValidationNoForm | FinancialValidationWithForm;

export interface BookingApplicationForm {
  checkInDate: string;
  checkOutDate: string;
  validCheckOutDates?: ValidCheckOutDates;
  contactDetails: BookingContactDetails;
  personalDetails: BookingPersonalDetails;
  personalDetailsError: boolean;
  secondOccupant: BookingSecondOccupant;
  secondOccupantError: boolean;
  residentialAddress: BookingResidentialAddress;
  residentialAddressError: boolean;
  financialValidation: BookingFinancialValidation;
  financialValidationError: boolean;
  howDidYouFindUs?: string;
  howDidYouFindUsError: boolean;
}

export type BookingStep = 'contacts' | 'personalDetails' | 'financialValidation' | 'payment';

export interface TReducerBooking {
  room?: TRoom;
  bookingOptions: BookingOptions;
  availability?: RoomAvailability;
  contractualConditions?: ContractualConditions;
  commercialConditions?: CommercialConditions;
  lightIdentity?: {
    lightIdentityStatus: 'Created'; // TBD
  };
  applicationForm: BookingApplicationForm;
  loading: number;
  loadingAvailability: boolean;
  error?: BookingErrorStates;
  step: BookingStep;
}
